@import 'variables.scss';

.detail {
  .full-height {
    table.fixed {
      tbody {
        height: 100%;
      }
    }
  }

  table.fixed.etfs {
    tbody {
      height: calc(100vh - 140px);
    }
  }

  .info-container {
    display: flex;

    .inside-div {
      width: 100%;
      height: 500px;
    }
  }

  #fundamentalchart {
    height: 1200px;
  }

  .yahoo {
    margin-left: 7.5px !important;
    border-color: #444;
  }

  .has-rank {
    margin-bottom: -7.5px;
  }

  &.affix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    table {
      position: relative;
      border-collapse: collapse;
      border-left: 7.5px solid #000;
      border-right: 7.5px solid #000;

      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed; /* even columns width , fix width of table too*/
      }
      thead {
        width: calc(100% - 1em);
      }
      table {
        width: 100%;
      }
      th {
        position: sticky;
        top: 0;
        cursor: pointer;
      }
      th,
      td {
        width: 150px;
      }
      td,
      th {
        &.Company,
        &.company,
        &.Insider,
        &.Title {
          width: 300px;
          overflow: hidden;
        }
      }
      tbody {
        height: calc(100vh - 18px);
        overflow: auto;
        display: block;

        &.table-with-labels {
          padding-bottom: 120px;
        }

        .tr-label {
          background-color: $darktext;
        }
      }
    }
  }

  .overview {
    margin-top: 60px;

    .jumbo {
      margin-bottom: -40px;
    }
  }

  .header-content {
    &.modal-header {
      padding-left: 10px;
      i {
        margin-right: 5px;
      }
    }
  }
  h1 {
    a {
      color: $white;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    button {
      margin-left: 10px;
      background-color: #444;
      font-size: 0.8em;

      &.btn-success {
        background-color: $success;
      }
    }
  }

  .h-scroll {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    vertical-align: top;
  }

  .double {
    .tab {
      line-height: 1;

      small {
        color: $white;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .tabs {
      .tab {
        white-space: nowrap;
      }
    }
    .modal {
      z-index: 9991;

      .detail-company {
        max-width: 100px;
      }
    }
    .info-container {
      display: flex;
      flex-direction: column;

      .inside-div {
        width: 100%;
        min-height: 400px;

        &.inside-div-chart {
          display: none;
        }
      }
    }

    .has-rank {
      display: none;
    }

    #fundamentalchart {
      min-height: 400px;
    }
  }
}
