@import 'variables.scss';

.chat {
  h1 {
    margin-bottom: 0;

    a {
      color: $white;
    }
  }
  .search-container {
    input {
      width: 100%;
      padding: 5px;
      padding-left: 15px;
    }
    i {
      top: 6px;
    }
  }

  .chat-module {
    display: flex;

    .left,
    .right {
      height: calc(100vh - 74px);
      overflow: auto;
    }

    .left {
      width: 25%;
      max-width: 600px;

      .small {
        font-size: 0.7em;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
      }

      a {
        display: block;
        color: $white;
        padding: 5px 5px 5px 10px;
        background-color: #111;
        border-bottom: 1px solid #222;

        &.active {
          border-left: 5px solid $neon1;
          color: $neon1;

          .faded {
            color: $white;
          }
        }
      }
    }

    .right {
      padding: 15px 20px;
      width: 100%;
    }

    .item {
      padding: 5px;
      border-bottom: 1px solid #222;
      font-size: 14px;
    }

    .form-group {
      //background-color: #444;
      padding: 8px 10px;
      min-width: 0;
      padding: 0;
      margin-bottom: 10px;

      input {
        min-width: 0;
        padding: 5px;
        min-height: 0;
        font-size: 14px;
        margin-bottom: 10px;
        border: 2px solid #444;
      }
    }

    button {
      font-size: 14px;
    }

    .item {
      p,
      h6 {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .chat {
    .chat-module {
      display: flex;
      flex-direction: column;

      .left,
      .right {
        width: 100% !important;
        max-width: 767px;
      }

      .right {
        padding: 10px;
        border-top: 1px solid #333;
      }

      .left {
        height: 175px;

        .small {
          margin-top: -5px;
        }
      }
    }
  }
}
