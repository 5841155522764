.watchlist {
  overflow: hidden;
  height: calc(100vh - 72px);

  .panel {
    max-width: 800px;
  }

  .h-scroll {
    margin-top: 0;
    overflow: auto;

    &.sub-header {
      height: 60px;
      background-color: #000;
      width: 100%;
      text-align: center;

      .tabs {
        .tab {
          height: 47px;
          min-width: 160px;
          padding-top: 10px;
        }
      }
    }

    table {
      position: relative;
      border-collapse: collapse;
      border: 10px solid #000;
      border-bottom: 0;
      tr {
        cursor: pointer;
      }

      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed; /* even columns width , fix width of table too*/
      }

      th {
        position: sticky;
        top: 0;
      }

      th,
      td {
        width: 150px;
        button {
          font-size: 0.75em;
          width: 85px;
        }

        &.Company {
          width: 200px !important;
        }
      }

      tbody {
        height: calc(100vh - 155px);
        overflow: auto;
        display: block;
      }
    }
  }
  @media screen and (max-width: 999px) {
    .tabs {
      width: 100% !important;
    }
  }
}
