.aggregates-page {
  overflow: hidden;
  height: calc(100vh - 72px);
  table {
    tbody {
      td {
        &.ETF {
          width: 200px;
        }
      }
    }
    thead {
      th {
        &.ETF {
          width: 200px;
        }
      }
    }
  }

  &.scroll-available {
    overflow: auto;

    table.fixed {
      thead {
        tr {
          th {
            position: unset;
            top: unset;
          }
        }
      }
      tbody {
        height: auto;
      }
    }
  }

  .loading-indicator {
    h1 {
      background-color: transparent !important;
      border: none !important;
    }
  }
  .badge {
    height: 20px;
    min-width: 20px;
    text-align: center;
  }

  .h-scroll {
    margin-top: 0;
    padding-bottom: 20px;
    overflow: auto;
  }

  h1 {
    align-items: center;
    padding: 10px;
    margin-bottom: 0;

    input {
      padding: 2.5px 10px;
      box-shadow: none;
    }
  }

  .ion-android-funnel {
    margin-left: 10px;
    margin-right: 2.5px;
    cursor: pointer;
    opacity: 0.8;
    font-size: 1.1em;

    &:hover {
      opacity: 1;
    }
  }

  .aggregate-filters {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .column {
      padding: 10px;
      height: 86vh;
      overflow: auto;
    }

    div {
      padding: 5px;
      cursor: pointer;

      strong {
        padding: 5px;
        cursor: pointer;
      }
    }

    .ion-android-checkbox-blank {
      color: rgba(255, 255, 255, 0.5);
      margin-right: 5px;
    }

    .success {
      margin-right: 5px;
    }
  }

  .actions-container {
    display: flex;
  }

  .aggregates-tutorial {
    //text-align: center;
    max-width: 800px;
    padding: 15px;
    margin: auto;
  }

  table {
    th,
    td {
      width: 150px;
    }
    tbody {
      height: calc(100vh - 95px);
      overflow: auto;
      display: block;
    }
  }
}

@media screen and (max-width: 767px) {
  .aggregate-filters {
    .column {
      width: 50%;
      overflow: auto;
      height: 300px;
    }
  }
}
