@import './variables.scss';

.navigation {
  .top-nav {
    text-align: center;
    background-color: #3c3c3c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;

    a {
      padding: 0 10px;
      height: 20px;
      border-bottom: 3px solid transparent;
      color: white;
      opacity: 0.5;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1px;

      &.active {
        opacity: 1;
        border-color: rgba(255, 255, 255, 0.5);
      }

      &.setter-container {
        padding-right: 0;
        opacity: 1;
        border-bottom: none;
        padding-top: 1.5px;
        margin-left: 15px;
        .setter {
          padding: 0 15px;
          height: 20px;
          border-bottom: 3px solid transparent;
          color: white;
          opacity: 0.5;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 1px;
          opacity: 0.5;
          /* background-color: rgba(255, 255, 255, 0.35);
          border-bottom: none;
          opacity: 0.75; */
        }
        .setter.active {
          /* background-color: rgba(255, 255, 255, 0.95);
          color: #000; */
          opacity: 1;
          border-bottom: 5px solid rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  nav {
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 1.25em;
    height: 50px;
    //background-color: #2e2e2e;
    background-color: #2e2e2e;

    .badge {
      margin-left: 2px;
    }

    input {
      vertical-align: top;
      padding: 2.5px 10px;
      font-size: 0.8em;
      box-shadow: none;
    }

    .nav-items {
      display: flex;

      a {
        color: $white;
        margin-left: 15px;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .ion-person {
      margin-right: 5px;
    }

    img {
      height: 30px;
      margin-top: -2px;
      margin-right: 5px;
      //cursor: pointer;

      &:hover {
        //opacity: 0.8;
      }
    }
  }

  .nav.dropdown {
    position: absolute;
    right: 15px;
    top: 74px;
    border-top: 0;
    font-weight: 600;
    min-width: 200px;
    cursor: pointer;
    z-index: 9;
    left: inherit;

    &.search-drawer {
      right: 0;
      left: -52px;
      top: 74px;
    }
  }

  .dropdown {
    border: 1px solid #000;

    .dropdown-item {
      padding: 7.5px 10px;
      text-align: left;
      font-size: 16px;
      cursor: pointer;
      //background-color: #444;

      &.divider {
        background-color: #444;
        padding: 1px 0 0 0;
      }

      &.danger {
        color: $danger;
        &:hover {
          color: $white;
          background-color: $danger !important;
        }
      }

      &:hover {
        background-color: #555 !important;
      }
    }

    position: absolute;
    top: 50px;
    border: 1px solid #eee;
    border-top: 0;
    background-color: #fff;
    font-weight: 600;
    min-width: 200px;
    cursor: pointer;
    z-index: 3;
    margin: auto;
    left: 0;
    right: 0;
    max-width: 300px;

    &.dropup {
      bottom: 48px;
      top: initial;
      border-top: 1px solid #eee;

      &.fund-group {
        left: 25%;
      }
    }
  }
}

@media screen and (max-width: 999px) {
  nav .nav-items {
    .item-dashboard,
    .item-watchlist {
      display: none;
    }
  }
}

@media screen and (max-width: 500px) {
  nav {
    img.logo {
      width: 80px;
      margin-top: 6px;
    }

    .logo-container {
      position: relative;

      sup {
        position: absolute;
        top: -1px;
        left: 0;
      }
    }

    .nav-items input {
      width: calc(150px);
    }
  }
}
