$primary-color: #007bff;
$white: #f0f0f0;
$danger: #f54100;
$success: #00e08e;
$highlight: #ffaa002c;

$neon1: #be005c;
$neon2: #00aaff;
$neon3: #aa00ff;
$neon4: #ffaa00;
$neon5: #1ea49d;
$neon6: #b6ade4;
$neon7: #00d9ff;

$darktext: rgb(45, 49, 56);
$lessdarktext: rgb(85, 92, 106);
$light1: #f9fafc;
$light2: #e1e8ef;
$light3: #ebeef3;
$light4: #ccd8e6;
