@import 'variables.scss';

.landing {
  sup {
    margin-left: 5px;
    opacity: 0.5;
  }

  a {
    color: $primary-color;
  }

  .panel {
    text-align: left;
    padding: 0;

    h3 {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    input {
      min-width: 200px;
    }

    button {
      width: 100%;
      margin: 15px auto;

      span {
        font-size: 0.75em;
        word-break: break-all;
      }
    }

    .form-group {
      padding: 10px 0;
    }

    p {
      font-weight: 400;
      margin-bottom: 10px;

      &.danger {
        margin-bottom: -15px;
      }
    }
  }

  .disclaimer {
    max-width: 800px;
    margin: 40px auto;
    padding: 0 5px;
  }
}
