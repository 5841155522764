@import 'variables.scss';

.all-dashboard {
  .panel-container {
    height: 320px;
  }
  .h-scroll {
    .panel-container {
      padding: 5px 2.5px;

      .panel {
        max-width: 420px;
      }
    }
  }

  &.etf .h-scroll .panel .panel-content table tr,
  &.etf .h-scroll .panel .panel-content {
    cursor: inherit !important;
  }

  .highlight {
    background-color: $highlight;
  }

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  table.fixed {
    tbody {
      height: calc(100vh - 109px) !important;
    }
    th,
    td {
      &.overlap {
        width: 320px !important;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .all-dashboard {
    .h-scroll .panel,
    .h-scroll .panel .panel-content {
      max-width: 420px;
    }
  }
}
