.crypto {
  //color: #ffb700;

  table.fixed tbody {
    height: calc(100vh - 10px) !important;

    td {
      overflow: hidden;
    }
  }

  .coin-img {
    height: 30px;
    width: 30px;
    background-color: #eee;
    margin-right: 10px;
    border-radius: 50%;

    &.faded {
      opacity: 0.25;
    }
  }
}

.coin.detail {
  h1 {
    min-width: none;
    .faded {
      margin-left: 5px;
    }
    i {
      margin-right: 5px;
    }
  }

  h2 {
    margin: 20px 5px;
  }

  .charts {
    height: calc(100vh - 60px);
    .chart-left,
    .chart-right {
      height: calc(100vh - 60px);
      overflow-y: auto;
      overflow-x: hidden;
      //height: 100%;

      .tv-widget {
        height: calc(100vh - 60px);
        max-height: 1200px;
      }
    }

    .chart-left {
      width: 50%;
      max-width: 600px;
      padding: 0 5px;
      font-size: 0.9em;
      background-color: rgba(78, 84, 120, 0.217);

      .chart-header {
        padding: 0 8px;
      }

      .chart-body {
        padding: 10px;

        h4 {
          margin: 5px;
        }
      }

      img {
        height: 50px;
        width: 50px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 5px;
        background-color: #eee;
      }

      .pills {
        padding: 0 0 10px;
        .pill {
          color: inherit;
          font-size: 0.85em;
          border-radius: 20px;
          height: 20px;
          width: 20px;
          padding: 2px 10px;
          background-color: rgba(140, 140, 140, 0.564);
          margin: 0 2.5px;
          line-height: 2.5;
          white-space: nowrap;

          &:hover {
            background-color: rgba(184, 184, 184, 0.564);
          }

          i {
            opacity: 0.5;
          }
        }
        .tag {
          font-size: 0.85em;
          border-radius: 20px;
          height: 20px;
          width: 20px;
          //text-decoration: underline;
          color: inherit;
          cursor: unset;
          margin: 0 5px;
          line-height: 2.5;
        }
      }
      table {
        tr {
          td {
            padding: 8px 5px;
          }
        }
      }
    }
    .chart-right {
      width: 100%;
      //padding: 20px;
    }
    .twitter-embed {
      text-align: center;
      width: 360px;
      margin: auto;
      padding: 30px 0;
      opacity: 0.8;

      .timeline-Tweet {
        background-color: transparent;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .coin.detail {
    .charts {
      flex-direction: column;

      .chart-left,
      .chart-right {
        width: 100%;
        height: 100%;
        max-width: 767px;
        overflow: unset;

        .tv-widget {
          height: 100%;
          max-height: 100%;
        }

        .twitter-embed {
          display: none;
        }
      }
    }
  }
}
