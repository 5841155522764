@import 'styles/variables.scss';

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  //color: #212529;
  text-align: left;
  letter-spacing: 0.25px;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr {
  &[title],
  &[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
  }
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #1988ff;
    opacity: 1 !important;
    text-decoration: underline;
  }

  sup {
    color: $white;
  }
}

/* a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}
 */

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;

  &:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

/* [data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: -26px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 2px;
  background: $lessdarktext;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  text-transform: capitalize;
} */

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button {
  text-transform: none;
}

select {
  text-transform: none;
  word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input {
  &[type='radio'],
  &[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }

  &[type='date'],
  &[type='time'],
  &[type='datetime-local'],
  &[type='month'] {
    -webkit-appearance: listbox;
  }
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number'] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    height: auto;
  }
}

.widget {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 60px;
  width: 400px;
  z-index: 99;
  border-radius: 8px;
}

.strong {
  font-weight: 600;
}

.preview-td {
  position: relative;
  overflow: visible;
}

.expanded {
  .widget {
    top: -165px;
  }
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* Custom Styles */

html,
body.darkmode {
  background-color: #000;
  color: $white;
}

.hidden {
  display: none !important;
}

:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.jumbo {
  font-size: 2em;
}

.search-container {
  position: relative;
  i {
    position: absolute;
    right: 3px;
    top: 3px;
    font-size: 16px;
    margin-right: 3px;
    cursor: pointer;
    opacity: 0.5;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.darkmode {
  .card,
  .modal-header,
  .modal .detail {
    background-color: #000;
    color: rgba(255, 255, 255, 0.8);
  }

  .chart-group {
    background-color: #111;
  }

  .panel {
    border-color: #000;
  }

  .alert {
    background-color: #222;
    color: #ccc;
  }
}

input {
  background-color: #222;
  color: #ccc;
}

.darkmode {
  .alert {
    background-color: #222;
  }

  .logo {
    filter: invert(100);
  }

  input {
    border: none;
  }

  button {
    background-color: #2e2e2e;
    color: #fff;
    border-color: #111;
    cursor: pointer;
  }

  .dashboard .watchlist-container .category-container {
    border-top: 1px solid #222;
  }

  button .btn-success {
    background-color: $success;
  }

  .nav.dropdown {
    border: none !important;
    background-color: #2e2e2e;
  }

  .dropdown {
    &.dropup {
      border: none !important;
      background-color: #2e2e2e;
    }

    .dropdown-item:hover,
    &.dropup .dropdown-item:hover {
      background-color: #444;
    }
  }

  .dashboard .watchlist-container {
    h3 a.btn-small {
      background-color: #2e2e2e;
      color: #888;
      border-radius: 0;
    }

    .category-container:last-child {
      border-bottom: 1px solid #2e2e2e;
    }

    h3 a.btn-small:hover {
      background-color: #444;
      text-decoration: none;
    }
  }

  .dropdown.dropup {
    bottom: 48px;
    border-top: none;
  }

  button.btn-primary {
    /* background-color: #4e4eff; */
    border-color: #111;
  }

  table.table-bordered {
    border: 1px solid #222;

    th,
    td {
      border: 1px solid #222;
    }
  }

  table.fixed {
    position: relative;
    border-collapse: collapse;
    border-left: 7.5px solid #000;
    border-right: 7.5px solid #000;

    tr {
      cursor: pointer;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }

    th {
      position: sticky;
      top: 0;
    }

    th,
    td {
      width: 150px;

      &.Company,
      &.company {
        width: 320px !important;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    tbody {
      height: calc(100vh - 161px);
      overflow: auto;
      display: block;
    }
  }

  &.Light {
    color: $darktext;
    background-color: $light1;

    .white {
      color: $darktext !important;
    }

    .double {
      .tabs {
        .tab {
          .white {
            color: $darktext !important;
          }
        }
      }
    }

    .logo {
      filter: invert(0);
    }

    .aggregates-page .aggregate-filters .ion-android-checkbox-blank {
      color: $light4;
    }

    .dropdown-item.divider {
      background-color: $light4 !important;
    }

    .btn.btn-secondary {
      color: white;
    }

    .success {
      color: #00c147;
    }

    .dashboard,
    .calendars,
    .chat {
      min-height: calc(100vh - 74px);
      background-color: $light1;
    }

    .announcement {
      background-color: $light4;
      border-color: $light4;
      color: $darktext;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      i {
        color: $darktext;
      }
    }

    .tool-tip {
      background-color: #fff;

      padding: 5px;
      border-radius: 3px;
    }

    .panel,
    .header-content.d-flex,
    .h-scroll.sub-header,
    input,
    .header-content.d-flex input,
    .card,
    .detail h1 a,
    .modal,
    .modal-header,
    .modal .detail,
    .rankings table th .secondary,
    .rankings table td .secondary,
    .landing.membership {
      background-color: $light1;
      color: $darktext;
      border-color: $light2;
    }

    input,
    .chat .chat-module .form-group input {
      border: 2px solid rgba(0, 0, 0, 0.1);
    }

    .detail h1 a {
      background-color: transparent;
    }

    .header-content.d-flex {
      background-color: $light2;
    }

    .dropdown-item.divider {
      background-color: $light1;
    }

    table {
      border-color: transparent;
    }

    .loader {
      border: 6px solid $light2;
      border-top: 6px solid $light4;
    }

    .nav.dropdown {
      background-color: $light3;
      border: 2px solid rgba(0, 0, 0, 0.1) !important;

      .dropdown-item {
        &:hover {
          background-color: $light4 !important;
          color: $darktext;
        }
      }
    }

    .chat .chat-module {
      .item {
        border-color: $light1;
      }
      .left a {
        background-color: $light3;
        color: $darktext;
        border-color: $light4;

        &:hover {
          background-color: $light4;
        }

        &.active .faded {
          color: $darktext;
          opacity: 0.5;
        }
      }
    }

    a sup,
    .badge {
      color: $light3;
    }

    table {
      tr {
        th {
          padding: 5px 7.5px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border: none;
          text-align: left;
          font-size: 0.75em;
          color: $darktext;
          border-top: 1px solid $light2;
          border-bottom: 1px solid $light2;
        }
      }
    }

    .panel {
      border-color: transparent;
      table {
        tr {
          td {
            border: none !important;
          }
        }
      }
    }

    .h-scroll .panel {
      border-color: $light2;
    }

    table {
      tr {
        td {
          border: none;
          border-bottom: 1px solid $light2;
        }
      }
    }

    .tab {
      border-bottom: 5px solid $light2;
    }

    .dashboard .charts {
      g text {
        fill: $darktext !important;
      }
      .chart-line-1,
      .chart-line-2,
      .chart-line-3,
      .chart-line-4,
      .chart-line-5,
      .chart-line-6,
      .chart-line-7,
      .chart-line-8 {
        background-color: $light4;
      }
    }

    text {
      fill: $darktext !important;
    }

    .horizontal-scroll {
      .card {
        background-color: $light1;
        color: $darktext;

        &:hover {
          background-color: $light2;
        }
      }
    }

    table {
      tbody {
        tr td,
        tr {
          &:hover {
            background-color: $light2 !important;
          }
          &:hover td {
            background-color: $light2 !important;
          }
        }
      }
    }

    nav {
      background-color: $light4;
      color: $darktext;

      a {
        color: $darktext;
      }
    }

    .faded.white {
      color: $darktext;
    }

    .detail {
      table {
        tbody {
          .tr-label {
            background-color: $light4;
          }
        }
      }
    }
  }
}

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}

h1 {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
}

canvas {
  cursor: pointer;
}

.loading-indicator {
  height: 60px;
  //width: 100%;
  text-align: center;
  margin: auto;
  z-index: 2;
  position: absolute;
  top: 40vh;
  left: 0;
  right: 0;
}

.loader {
  border: 6px solid #2e2e2e;
  border-top: 6px solid #888;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.75s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.link {
  cursor: pointer;
}

table {
  width: 100%;
}

.faded {
  opacity: 0.5;
}

.text-center {
  text-align: center;
  width: 100%;
}

sup {
  font-size: 12px;
  vertical-align: 5px;
}

.ads-container {
  background: #444;
  padding: 10px 15px;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

/* .detail {
  padding: 0 20px 20px;

  .chartjs-hidden-iframe {
    display: none !important;
  }
} */

table .table-striped {
  th,
  td {
    padding: 5px;
  }
}

.detail table {
  td,
  th {
    padding: 5px;
  }
}

.detail {
  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }

  img {
    height: 60px;
    width: auto;
    border-radius: 8px;
    margin-right: 10px;
  }
}

/* .detail-top {
  .summary {
    max-width: 600px;
    margin: 10px auto 0;
    position: relative;
    z-index: 1;
    background-color: black;
    padding: 0 5px;

    table td:first-child {
      text-align: right;
      padding-right: 5px;
    }
  }

  min-height: 300px;
} */

.detail {
  .infobox.vcard {
    width: 100% !important;
  }

  h2 {
    margin-top: 40px;
    margin-bottom: 5px;
  }

  h3,
  h5 {
    margin-bottom: 5px;
  }

  p {
    margin-top: 5px;
  }

  table {
    th,
    td {
      text-align: left;
    }
  }
}

.success {
  color: rgb(18, 209, 139);
}

.danger {
  color: $danger;
}

a {
  cursor: pointer;

  /* color: #4e4eff; */
  font-weight: 600;

  &:hover {
    /* color: #4e4eff; */
    opacity: 0.8;
    text-decoration: none;
  }

  &.back-button {
    display: block;
    margin-top: -10px;
  }
}

.small {
  font-size: 0.8em;
  line-height: 1.8;
}

.badge {
  background-color: #2e2e2e;
  font-size: 12px;
  padding: 0 5px;
  border-radius: 30px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  vertical-align: top;

  &.primary {
    background-color: #be005c;
  }
}

.alert {
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
  border-top: 2px solid rgba(0, 0, 0, 0.05);
  text-align: center;
  background-color: #fff;
  padding: 5.5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;

  .btn-secondary {
    font-weight: 600;
    letter-spacing: 0.5px;
    background-color: #ff007a;
  }

  p {
    margin-bottom: 0;
  }

  button.btn-secondary {
    font-size: 14px;
    font-weight: 600;
  }
}

/* Watchlist */
.dashboard {
  padding: 0 0 20px;
  position: relative;
  min-height: 100%;

  .watchlist-container {
    max-width: 800px;
    margin: auto;

    table {
      margin: 10px 0;
      border-top: 1px solid #eee;
    }

    h3 a.btn-small {
      background-color: #eee;
      border-radius: 5px;
      padding: 2.5px 5px;
      font-size: 12px;
      margin-left: 10px;
    }

    .category-container {
      margin: 10px auto;
      border-top: 1px solid #eee;
      padding-left: 5px;

      &:last-child {
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
      }
    }
  }

  .list-mode .card {
    width: 100%;
    max-width: 400px;
    margin: auto;
    align-items: center;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid #333;
    padding: 5px 0;
    cursor: pointer;

    button {
      width: calc(20% - 5px);
      font-size: 11px;
      padding: 5px;
      margin-right: 5px;
    }

    div {
      width: 80%;
      align-items: flex-start;
      text-align: left;
      display: flex;
      flex-direction: row;

      h5,
      p {
        font-size: 16px;
        padding: 5px;
        margin: 0;
        text-align: left;
      }

      h5 {
        width: 60px;
      }
    }
  }
}

/* Watchlist */
table tr {
  td {
    padding: 5px 7.5px;
    text-transform: capitalize;
    border-top: 1px solid #111;
    text-align: left;
  }

  th {
    padding: 5px 7.5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    //border: 1px solid #111;
    text-align: left;
    font-size: 0.75em;
    color: $white;
    /* opacity: 0.75; */
    //background-color: #333;
    border-top: 1px solid #222;
  }

  &:hover td {
    background-color: #151515 !important;
  }
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard {
  .watchlist-container {
    table th {
      padding-top: 10px;
    }

    h3 {
      padding: 10px 0 0;
      margin-bottom: 0;

      i {
        padding: 0 10px;
      }
    }
  }

  h1 {
    padding-top: 30px;
    margin-bottom: 0;

    small {
      display: block;
      opacity: 0.5;
      font-size: 0.5em;
    }
  }

  p {
    margin-top: 0;
  }
}

.chart-group {
  border: 4px solid #111;
  border-bottom: 2px solid #111;
  padding-top: 20px;
}

.dashboard {
  h2,
  h5,
  h4 {
    text-align: center;
    margin-bottom: 0;
  }

  h2 {
    margin-top: 2em;
  }
}

h4 {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.page {
  padding: 0 20px 20px;
  max-width: 800px;
  margin: auto;
}

.panel-container {
  padding: 5px;

  &.summary {
    padding: 5px 5px;
    margin-bottom: -10px;
  }

  .h-scroll {
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
  }
}

.h-scroll {
  &.panel {
    h5,
    h6 {
      margin-bottom: 5px;
      line-height: 1.25;
    }

    h4 {
      text-transform: capitalize;
      font-size: 18px;
      letter-spacing: 0.5px;
      text-align: center;
    }

    table tr {
      &:hover {
        color: #fff;
      }

      td {
        padding: 5px 0;
      }
    }
  }
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.landing {
  text-align: center;
  display: flex;
  z-index: 1;

  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  background-position: center;
  background-size: cover;
  padding: 40px 15px;

  /* background-image: url('https://g.foolcdn.com/image/?url=https%3A%2F%2Fg.foolcdn.com%2Feditorial%2Fimages%2F560464%2Fgetty-stock-market-chart.jpg&w=700&op=resize') */

  h1,
  p {
    margin: 0 auto;
  }

  h3 {
    margin-bottom: 0;
  }

  small {
    font-size: 0.6em;
  }
}

.panel {
  padding: 15px;
  border: 3px solid #eee;
  max-width: 400px;
  margin: 20px auto;
  width: 100%;
}

.landing {
  .landing-proposition {
    max-width: 600px;
    margin: 30px auto;
    border: 1px solid #2e2e2e;
    padding: 0 30px;
    text-align: left;

    p {
      margin-bottom: 15px;
    }

    .tutorial {
      border: none;
      text-align: left;
      padding: 0;

      p {
        font-size: 16px;
      }
    }
  }

  &.membership {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: #111;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20px;

    .panel {
      border: none;
      padding: 20px;
      max-width: 600px;
    }

    .ion-ios-close-empty {
      color: #fff;
      font-size: 4em;
      position: absolute;
      top: 10px;
      right: 30px;
      line-height: 0;
    }
  }
}

.background-blur {
  background-image: url('https://lucidtracking.s3.amazonaws.com/assets/appassets/background.png');
  background-size: cover;
  background-position: top;
  filter: blur(100px);
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 50px;
}

.foreground {
  height: 100%;
  width: 100%;
  z-index: 2;
}

.landing.membership ol {
  text-align: left;
  margin: 15px auto 20px;
  padding: 0 15px 0 20px;

  li {
    margin-bottom: 10px;
  }
}

.form-group {
  text-align: left;
  display: block;
  padding: 5px;
  width: 100%;
  min-width: 300px;
  margin: auto;

  label,
  input {
    text-align: left;
    display: block;
    padding: 5px;
    width: 100%;
    min-width: 300px;
    margin: auto;
  }

  &.hidden {
    display: none;
  }

  label {
    padding: 5px 0;
  }
}

button,
.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 1px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

button.btn-primary,
a.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

button.btn-secondary,
a.btn-secondary {
  color: #fff;
  background-color: #2e2e2e;
  border-color: #2e2e2e;
}

.horizontal-scroll {
  display: flex;
  align-items: center;
  overflow: auto;
  width: 100%;

  .card {
    width: 150px;
    min-width: 150px;
    float: left;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100px;
    background-color: #111;
    margin: 10px 1px;
    text-align: center;

    &:hover {
      background-color: $darktext;
    }

    p,
    h5 {
      margin: 0 auto;
    }

    p {
      font-size: 10px;
    }

    button {
      font-size: 10px;
      width: 100px;
      margin: 5px auto 0;
      font-weight: 600;
      padding: 2.5px;

      &.btn-success {
        background-color: #222;
        color: $success;
        border-color: #222;

        &:hover {
          background-color: #333;
        }
      }
    }
  }
}

button {
  &.btn-success {
    color: #fff;
    background-color: rgb(0, 194, 123);
    border-color: rgb(0, 194, 123);
  }

  &:hover {
    opacity: 0.9;
  }
}

.btn-block {
  width: 100%;
}

.landing button {
  margin: 15px auto 5px;
  width: calc(100% - 10px);
}

.faq-container {
  margin: 30px auto;
}

.modal-small {
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  .modal {
    //position: relative;
    background-color: black;
    overflow: scroll;
    border-radius: 8px;

    .overview {
      margin-top: 0;
      padding-top: 40px;
      overflow: auto;
      height: 100vh;
      padding-bottom: 80px;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  background-color: black;
  overflow: scroll;
  z-index: 10;

  .detail {
    .board {
      max-width: 600px;
      margin: 40px auto;

      .comment-container {
        .form-group {
          padding: 10px 0 0;
        }

        p,
        h6 {
          margin: 0;
        }

        h6 {
          margin-top: 20px;
        }

        .header-content {
          padding-top: 0;
          padding-bottom: 0;
        }

        .board {
          padding: 15px;
        }

        .form-group {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

.section-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 73px);
}

.collapsed {
  height: 202px;
  overflow: hidden;
  margin-bottom: 30px;
  border: 1px solid #2e2e2e;
  border-top: 0;
}

.collapse-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  border: 1px solid #444;
  padding: 5px 10px;
  padding: 5px 10px;
  border: 1px solid #2e2e2e;
  margin-bottom: 0;
  cursor: pointer;
  background-color: #222;
}

.ARK {
  .tabs {
    .tab {
      &.active {
        border-bottom: 5px solid #a64dff;
        color: #a64dff;
      }
    }
  }
  &.chat {
    .chat-module {
      .left {
        a {
          &.active {
            border-left: 5px solid #a64dff;
            color: #a64dff;
          }
        }
      }
    }
  }
}

.Roundhill.Investments {
  .tradenotedir,
  .tradenoteshares,
  .tradenotevalue {
    display: none;
  }
  .tabs {
    .tab {
      &.active {
        border-bottom: 5px solid #007fda;
        color: #007fda;
      }
    }
  }
  &.chat {
    .chat-module {
      .left {
        a {
          &.active {
            border-left: 5px solid #007fda;
            color: #007fda;
          }
        }
      }
    }
  }
}

.Global.X {
  .tradenotedir,
  .tradenoteshares,
  .tradenotevalue {
    display: none;
  }
  .tabs {
    .tab {
      &.active {
        border-bottom: 5px solid #ff5400;
        color: #ff5400;
      }
    }
  }
  &.chat {
    .chat-module {
      .left {
        a {
          &.active {
            border-left: 5px solid #ff5400;
            color: #ff5400;
          }
        }
      }
    }
  }
}

.tabs {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  //width: 100%;

  .tab {
    padding: 5px 10px;
    border-bottom: 5px solid #2e2e2e;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    min-width: 120px;

    &.active {
      border-bottom: 5px solid #ff007a;
      color: #ff007a;
    }
  }
}

table.table-bordered {
  border: 1px solid #eee;

  th,
  td {
    border: 1px solid #eee;
  }

  th,
  td {
    padding: 2px 6px;
  }
}

.crypto table img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.floating-button {
  position: fixed;
  z-index: 2;
  right: 10px;
  bottom: 10px;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  background-color: #888;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 2em;
  line-height: 1.6;
  cursor: pointer;

  &:hover {
    color: rgba(0, 0, 0, 0.25);
  }
}

.board {
  .comments-container {
    display: flex;
    justify-content: center;
    margin-top: -20px;
  }

  .comments {
    border: none;
    position: relative;
    top: unset;
    right: unset;
    left: unset;
    height: unset;
    width: 400px;
  }

  .info {
    width: calc(100% - 400px);
  }

  .comments,
  .info {
    height: calc(100vh - 51px);
    overflow: auto;
  }

  #tradingview_7ecdd-wrapper {
    height: 50vh;
  }

  .item button {
    font-size: 10px !important;
    margin-left: 5px;
  }
}

img.sample {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: 2em auto 0;
  border-radius: 8px;
  opacity: 0.75;
}

.tutorial {
  max-width: 600px;
  margin: 40px auto;
  text-align: center;
  border: 2px solid #222;
  padding: 20px 15px 0;

  p {
    font-size: 14px;
    margin-bottom: 5px;
  }

  img {
    width: 80%;
    height: auto;
    margin: 20px auto 0;
  }
}

.item-account {
  padding: 0 7.5px;

  /* 
  padding: 10px 7.5px;
  background-color: #2e2e2e;
  border-radius: 50px;
  height: 60px;
  width: 60px; */

  .ion-person {
    font-size: 1.25em;
    line-height: 1;
  }

  .ion-arrow-down-b {
    font-size: 10px;
  }
}

.badge {
  padding: 2.5px 5px;
  background-color: rgb(88, 88, 88);
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 10px;
  cursor: help;
}

.pumpdump a {
  font-size: 13px;
  margin: 8px 2.5px 0;
}

.tradingview-widget-container.hidden-desktop,
.line-mobile.hidden-desktop {
  display: none;
}

@media screen and (min-width: 1000px) {
  .comments {
    left: 70%;
  }

  .dropdown-item {
    &.hidden-desktop {
      display: none;
    }

    &.hidden-mobile {
      display: block;
    }
  }

  .tradingview-widget-container.hidden-mobile {
    display: block;
  }
}

@media screen and (max-width: 999px) {
  .alert {
    font-size: 12px;

    button.btn-secondary {
      font-size: 12px;
      padding: 5px 7.5px;
    }
  }

  .dropdown-item.hidden-desktop {
    display: block;
  }

  .hidden-mobile {
    display: none;
  }

  .tradingview-widget-container.hidden-desktop,
  .line-mobile.hidden-desktop {
    display: block;
  }

  .comments {
    top: 50px;
  }
}

/* Latest clean up the rest */
.header-content {
  &.d-flex {
    align-items: center;
    padding: 0 10px 0 15px;
    background-color: #222;
    min-height: 50px;
    margin: 0;
    input {
      background-color: #111;
    }
    h1 {
      margin-bottom: 0;
      span {
        margin-right: 5px;
      }
    }
    .tabs {
      .tab {
        padding: 10px !important;
      }
    }
  }
}

/* End Latest */

@media screen and (max-width: 767px) {
  .navigation .nav.dropdown.search-drawer {
    right: 72px;
    left: unset;
    top: 74px;
  }
}

@media screen and (max-width: 500px) {
  table.striped.fixed {
    border-left: 0 solid #000;
    border-right: 0 solid #000;
  }
  .item-account {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    height: auto;
    width: auto;
  }

  .dropdown.dropup {
    font-size: 16px;
  }

  h2 {
    font-size: 1.25em;
  }

  .comments {
    left: 0;
    min-width: 300px;
    top: 50px;
    bottom: 0;
    z-index: 2;
  }

  .h-scroll .panel {
    padding: 2.5px 5px;
    max-width: 300px;
    overflow-x: hidden;

    h1 {
      font-size: 14px;
    }

    h4 {
      font-size: 16px;
    }

    table td {
      font-size: 15px;
    }

    .panel-content {
      max-width: 300px;
    }

    b {
      font-size: 14px;
    }
  }

  .modal {
    .modal-header {
      padding: 10px 10px;
      position: relative;
      overflow: auto;
    }

    .overview {
      margin-top: 0;
    }

    .header-content.d-flex h1 {
      min-width: 168px;
    }

    .detail {
      //padding: 0px 10px 20px 10px;

      iframe {
        height: calc(100vh - 51px) !important;
      }
    }

    /* .summary table td {
      font-size: 0.75em;
    } */
  }

  .tabs .tab {
    padding: 5px 8px;
    min-width: 0;
  }

  /* .modal .detail-top {
    margin-top: 0;
    padding: 10px;
    min-height: 200px;
  } */
}

@supports (-ms-ime-align: auto) {
  /* *::-webkit-scrollbar {
    width: 12px;
    overflow-y: auto;
  }

  *::-webkit-scrollbar-track {
    background: black;
    overflow-y: auto;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #222;
    border-radius: 20px;
    border: 3px solid black;
    overflow-y: auto;
  } */

  .aggregates-page,
  .dashboard.affix,
  .rankings,
  .watchlist,
  .calendars,
  .detail.affix {
    height: unset;
    overflow: scroll;
    table.fixed {
      thead {
        tr {
          th {
            position: unset;
            top: unset;
          }
        }
      }
      tbody {
        height: auto;
      }
    }
  }
}

/* Microsoft Edge Browser 15+ - @supports method */

/* @supports (-ms-ime-align: auto) and (-webkit-text-stroke: initial) {
  *::-webkit-scrollbar {
    width: 12px; 
    overflow-y: auto;
  }

  *::-webkit-scrollbar-track {
    background: black; 
    overflow-y: auto;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #222; 
    border-radius: 20px; 
    border: 3px solid black; 
    overflow-y: auto;
  }
} */
