.join {
  .join-container {
    padding: 5%;
    text-align: center;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 9, 121, 1) 35%,
      rgba(0, 212, 255, 1) 100%
    );
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;

    h4 {
      margin-bottom: 10px;
      vertical-align: middle;
    }

    .ion-ios-close-empty {
      margin: 0 5px;
    }

    .logo {
      width: 70px;
      height: auto;
      margin: -2px 0 0;
    }

    .panel {
      text-align: center;
      background-color: rgb(22, 27, 40);
      padding: 40px 20px;
      border: none;

      img {
        height: 140px;
        width: 140px;
        border-radius: 80px;
        margin: 0 auto 20px;
      }
    }
  }
}
