.calendars {
  .h-scroll {
    margin-top: 0;
    overflow: auto;

    .options.fixed {
      td,
      th {
        width: 120px;
      }
    }
  }
}
