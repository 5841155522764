@import './variables.scss';

.rankings {
  table {
    position: relative;
    border-collapse: collapse;
    border-left: 7.5px solid #000;
    border-right: 7.5px solid #000;

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }
    thead {
      width: calc(100% - 1em);
    }
    th {
      position: sticky;
      top: 0;
    }
    th,
    td {
      width: 150px;
    }
    td,
    th {
      &.Company,
      &.company {
        width: 300px;
        overflow: hidden;
      }

      &.rank {
        width: 80px !important;
      }
    }
    tbody {
      height: calc(100vh - 95px);
      overflow: auto;
      display: block;
    }
  }

  .help-text {
    max-width: 800px;
    margin: 20px auto;

    p {
      margin: 5px 0;
    }
  }

  h2 {
    margin-top: 1em;
    margin-bottom: 0;
    text-align: center;
  }

  .panel {
    margin-top: 0;
    max-width: 800px;
  }

  table {
    th,
    td {
      &:first-child {
        width: 70px;
      }
      .secondary {
        color: $white;
      }
    }

    th {
      i {
        margin-left: 3px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .h-scroll {
      .tabs {
        justify-content: flex-start;
        .tab {
          min-width: 100px;
        }
      }
    }
  }
}
