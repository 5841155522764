.charts {
  g {
    cursor: pointer;
    color: white;

    text {
      fill: #ffffff !important;
      text-transform: capitalize;
    }
  }

  .tool-tip {
    background-color: #222;
    padding: 5px;
    border-radius: 3px;
  }

  .chart-container {
    margin: 30px auto 0;
    //min-width: 1000px;
    h3 {
      margin-top: 30px;
      margin-bottom: -30px;
    }
  }

  .charts-combined {
    position: relative;
    height: 400px;

    h3 {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .chart-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
