@import 'variables.scss';
@import 'animation.scss';

.white {
  color: $white;
}

.dashboard {
  td,
  th {
    &.Company,
    &.company {
      max-width: 300px;
      overflow: hidden;
    }
  }

  h5 {
    max-width: 800px;
    margin: auto;
  }

  section {
    display: flex;
    width: 100%;
    max-width: 800px;
    margin: auto;
    align-items: center;
    justify-content: center;

    b {
      margin-right: 7.5px;
      opacity: 0.5;
      cursor: pointer;
    }
    .item {
      padding: 2.5px 7.5px;
      cursor: pointer;
      font-weight: 600;
    }
  }

  .charts {
    position: relative;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .chart-line-1 {
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #222;
      top: 40px;
    }

    .chart-line-2 {
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #222;
      top: 80px;
    }

    .chart-line-3 {
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #222;
      top: 120px;
    }

    .chart-line-4 {
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #222;
      top: 160px;
    }

    .chart-line-5 {
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #222;
      top: 200px;
    }

    .chart-line-6 {
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #222;
      top: 240px;
    }

    .chart-line-7 {
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #222;
      top: 280px;
    }

    .chart-line-8 {
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #222;
      top: 320px;
    }
  }

  &.affix {
    position: fixed;
    top: 74px;
    left: 0;
    right: 0;
    bottom: 0;

    table {
      position: relative;
      border-collapse: collapse;
      border-left: 7.5px solid #000;
      border-right: 7.5px solid #000;

      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed; /* even columns width , fix width of table too*/
      }
      thead {
        width: calc(100% - 1em);
      }
      table {
        width: 100%;
      }
      th {
        position: sticky;
        top: 0;
      }
      th,
      td {
        width: 150px;
      }
      td,
      th {
        &.Company,
        &.company {
          width: 300px;
          overflow: hidden;
        }
      }
      tbody {
        height: calc(100vh - 120px);
        overflow: auto;
        display: block;

        td {
          button {
            font-size: 0.75em;
            min-width: 85px;
          }
        }
      }
    }
  }
  .announcement {
    background-color: #222;
    width: 100%;
    padding: 5px;
    text-shadow: none;
    border-bottom: 1px solid #111;
    border-top: 1px solid #111;
    color: #ccc;
    overflow: auto;

    &.link {
      font-size: 0.75em;
      text-align: center;

      i {
        color: #fff;
      }
    }

    .emoji {
      margin-right: 5px;
    }

    .announcement-body {
      text-align: left;
      font-size: 1.1em;
      margin: 10px auto;
      max-width: 600px;
      padding: 0 15px 15px;
      height: calc(100vh - 94px);

      h6 {
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 20px;
      }
    }
  }

  .header-content {
    h1 {
      margin: 0;
      padding: 0;

      span {
        margin: 0;
      }
    }
  }

  h2 {
    small {
      margin-left: 5px;
    }
  }

  h3 {
    margin: 0px auto 2px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  .panel {
    small.faded {
      margin-left: 5px;
    }
  }

  .badge {
    padding: 1.5px 5.5px 2.5px;
  }

  .h-scroll {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    vertical-align: top;

    &.sub-header {
      height: 60px;
      background-color: #000;
      width: 100%;

      .tabs {
        .tab {
          height: 47px;
          min-width: 150px;
          padding-top: 10px;
        }
      }

      &.double {
        .tabs {
          .tab {
            margin: 0;
            height: 47px;
            line-height: 1.1;
            padding-top: 5px;

            small,
            .small {
              line-height: 1.1;
            }

            cursor: pointer;
          }
        }
      }
    }

    h4 {
      padding: 2.5px;
    }

    /* margin-top: 42px; */

    .panel {
      padding: 0 !important;

      .btn-primary {
        font-size: 14px;
        padding: 2.5px 7.5px;
      }

      .panel-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        border-radius: 1px;
        cursor: pointer;
        min-width: 320px;
        align-items: flex-start;
        overflow: auto;
        height: 300px;
        padding: 0px 0 50px;
      }

      display: inline-block;
      text-align: center;
      min-height: 300px;
      min-width: 320px;
      margin: 15px 2.5px 20px;
      padding: 5px 15px;
      max-height: 300px;
      overflow: hidden;
      background-color: #111;
      vertical-align: top;

      &.expanded {
        max-height: 1000px;
        height: 1000px;

        .panel-content {
          height: 1000px;
          padding-bottom: 50px;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    section {
      align-items: center;
      justify-content: flex-start;
    }
  }
}
